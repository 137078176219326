<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
  modalId: {
    type: String,
    required: true,
    default: '',
  },
  position: {
    type: String as PropType<'left' | 'middle' | 'right'>,
    default: 'middle',
  },
  hasConfirmBtn: {
    type: Boolean,
    default: false,
  },
  hasCancelBtn: {
    type: Boolean,
    default: false,
  },
  hasAlignBtnClose: {
    type: Boolean,
  },
  paddingHeader: {
    type: String as PropType<'padding-none' | 'padding-s' | 'padding-m' | 'padding-l'>,
    default: 'padding-m',
  },
  paddingContent: {
    type: String as PropType<'padding-none' | 'padding-s' | 'padding-m' | 'padding-l'>,
    default: 'padding-m',
  },
  paddingFooter: {
    type: String as PropType<'padding-none' | 'padding-s' | 'padding-m' | 'padding-l'>,
    default: 'padding-m',
  },
  isQuoteModal: {
    type: Boolean,
    default: false,
  },
  hasFixedCloseBtn: {
    type: Boolean,
    default: false,
  },
})

const openModal = ref(false)

const toggleModal = (scrollToId) => {
  openModal.value = !openModal.value
  document.body.classList.toggle('modal-open')

  const scrollToIdElement = document.getElementById(scrollToId)
  if (null !== scrollToIdElement) {
    setTimeout(() => {
      scrollToIdElement.scrollIntoView()
    }, 0)
  }
}

const handleClickOutside = (event) => {
  // TODO: Override is not click outside. Refactor this when you have time
  if (event.target.className === 'see-more-cta' || event.target.className === 'itinerary-image') {
    return
  }

  if (openModal.value != false) {
    openModal.value = false
    document.body.classList.toggle('modal-open')
  }
}

const modalClasses = computed(() => {
  return {
    open: openModal.value,
    left: props.position === 'left',
    middle: props.position === 'middle',
    right: props.position === 'right',
    quote_modal: props.isQuoteModal,
  }
})

defineExpose({
  toggleModal,
})
</script>

<template>
  <div
    tabindex="0"
    role="button"
    class="modal-btn-container"
    title="Toggle Modal"
    @click.stop="toggleModal"
    @keyup.enter.stop="toggleModal"
  >
    <slot name="button-modal" />
  </div>
  <Teleport to="body">
    <div
      :id="modalId"
      class="modal-container"
      :class="modalClasses"
    >
      <Transition>
        <div
          v-click-outside="handleClickOutside"
          class="modal"
          :class="{
            'quote-modal': isQuoteModal,
          }"
        >
          <div
            class="modal-header"
            :class="paddingHeader"
          >
            <UiElementsButton
              :color-style="'empty'"
              :size="'small'"
              :class="{ 'btn-close': !hasAlignBtnClose, 'btn-close-align': hasAlignBtnClose, 'fixed-btn': hasFixedCloseBtn }"
              @click="toggleModal"
            >
              <UiIconsCross />
            </UiElementsButton>
            <slot name="modal-title" />
          </div>
          <div
            class="modal-content"
            :class="paddingContent"
          >
            <slot name="modal-content" />
          </div>
          <div
            v-if="hasCancelBtn || hasConfirmBtn || 'undefined' !== typeof $slots['custom-btn']"
            class="modal-footer"
            :class="[{ 'modal-action': 'undefined' === typeof $slots['custom-btn'] }, 'undefined' === typeof $slots['custom-btn'] ? paddingFooter : '']"
          >
            <UiElementsButton
              v-if="hasCancelBtn"
              :title="$t('modal.button.cancel')"
              :size="'large'"
              :color-style="'outline'"
              :variant="'light'"
              @click="toggleModal"
            >
              {{ $t('modal.button.cancel') }}
            </UiElementsButton>
            <UiElementsButton
              v-if="hasConfirmBtn"
              :title="$t('modal.button.confirm')"
              :size="'large'"
              :color-style="'gradiant'"
              :variant="'light'"
              @click="toggleModal"
            >
              {{ $t('modal.button.confirm') }}
            </UiElementsButton>
            <slot name="custom-btn" />
          </div>
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<style lang="scss">
body {
  &.modal-open {
    overflow: hidden;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: none;
  overflow: hidden;
  outline: 0;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    position: fixed;
  }

  &.open {
    display: flex;
    background: rgba(77, 77, 77, .7);
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      &.middle {
        padding: $space-xl $space-4xl $space-xl $space-4xl;
      }

      &.left, &.right {
        .modal {
          max-width: 630px;
        }
      }

      &.left {
        .modal {
          margin-right: auto;
        }
      }

      &.right {
        .modal {
          margin-left: auto;
        }
      }

      &.quote_modal {
        padding: 0;

        .modal-header, .modal-action {
          display: none;
        }

        .modal-content {
          height: 100%;
        }
      }
    }
  }

  .modal {
    background: white;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    &.quote-modal {
      padding: 0;

      .modal-header {
        display: none;
      }

      .modal-content {
        margin: 0;
      }
    }

    .modal-header {
      position: relative;

      &.padding-s {
        padding: 1em;

        .btn-close-align {
          right: 1rem;
        }
      }

      &.padding-m {
        padding: 1.5em 1.5em;

        .btn-close-align {
          right: 1.5rem;

          &.fixed-btn {
            right: 1rem;
          }
        }
      }

      &.padding-l {
        padding: 2em;

        .btn-close-align {
          right: 2rem;
        }
      }

      > h3, > h4 {
        text-transform: initial;
        text-align: left;
        margin: 0;
      }

      .btn-close {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      .btn-close-align {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 1.5rem;
      }

      .fixed-btn {
        position: fixed;
        top: 1.5rem;
        z-index: 5;
        background: var(--color-white);

        &:hover {
          background: var(--color-navy-10);
        }
      }
    }

    .modal-content {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      flex: 1;

      &.padding-s {
        padding: 1em;
      }

      &.padding-m {
        padding: 1.5em;
      }

      &.padding-l {
        padding: 2em;
      }
    }

    .modal-footer {
      &.padding-s {
        padding: 1em;
      }

      &.padding-m {
        padding: 1.5em 1.5em;
      }

      &.padding-l {
        padding: 2em;
      }
    }

    .modal-action {
      margin-top: auto;
      display: flex;
      justify-content: center;
      gap: 0.5rem;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        gap: 1.5rem;
      }
    }
  }
}
</style>
